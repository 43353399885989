import React from 'react';
import Icon, {SvgIconProps} from "./Icon";


const CancelIcon: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
  return <Icon translateY="-1px" viewBox="0 0 18 18" path="M12.5156 7.03906L10.0547 9.5L12.5156 11.9844C12.8203 12.2656 12.8203 12.7578 12.5156 13.0391C12.2344 13.3438 11.7422 13.3438 11.4609 13.0391L9 10.5781L6.51562 13.0391C6.23438 13.3438 5.74219 13.3438 5.46094 13.0391C5.15625 12.7578 5.15625 12.2656 5.46094 11.9844L7.92188 9.5L5.46094 7.03906C5.15625 6.75781 5.15625 6.26562 5.46094 5.98438C5.74219 5.67969 6.23438 5.67969 6.51562 5.98438L9 8.44531L11.4609 5.98438C11.7422 5.67969 12.2344 5.67969 12.5156 5.98438C12.8203 6.26562 12.8203 6.75781 12.5156 7.03906Z"
               {...props}/>
});

export default CancelIcon;
