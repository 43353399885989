import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import { Box, List, DrawerProps, SvgIcon } from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListItemLink from '../itemComponents/ListItemLink'
import { ReactComponent as LogoExtended } from "@bytenite/components/src/assets/bytenite-logo-horizontal-white.svg";
import { ReactComponent as Logo } from "@bytenite/components/src/assets/bytenite-logo.svg";
import { ReactComponent as DocumentationIcon } from "@bytenite/components/src/assets/navBarIcons/book.svg";
import { ReactComponent as DocumentationFilledIcon } from "@bytenite/components/src/assets/navBarIcons/book-filled.svg";
import { ReactComponent as StatisticsIcon } from "@bytenite/components/src/assets/navBarIcons/chart-simple.svg";
import { ReactComponent as StatisticsFilledIcon } from "@bytenite/components/src/assets/navBarIcons/chart-simple-filled.svg";
import { ReactComponent as ChevronRightIcon } from "@bytenite/components/src/assets/navBarIcons/chevrons-right.svg";
import { ReactComponent as ChevronLeftIcon } from "@bytenite/components/src/assets/navBarIcons/chevrons-left.svg";
import { ReactComponent as WalletIcon } from "@bytenite/components/src/assets/navBarIcons/dollar-sign.svg";
import { ReactComponent as WalletFilledIcon } from "@bytenite/components/src/assets/navBarIcons/dollar-sign-filled.svg";
import { ReactComponent as EncodingIcon } from "@bytenite/components/src/assets/navBarIcons/gear.svg";
import { ReactComponent as EncodingFilledIcon } from "@bytenite/components/src/assets/navBarIcons/gear-filled.svg";
import { ReactComponent as HeadsetIcon } from "@bytenite/components/src/assets/navBarIcons/headset.svg";
import { ReactComponent as HeadsetFilledIcon } from "@bytenite/components/src/assets/navBarIcons/headset-filled.svg";


//import SvgIcon from "@mui/icons-material/SvgIcon";

export interface SidebarProps extends DrawerProps {
    updateSidebarOpen: (isOpen: boolean) => void
    sidebarOpen: boolean
    addSidebarEventListener: (cb: (e: CustomEvent<{value: boolean}>) => void) => void
    removeSidebarEventListener: (cb: (e: CustomEvent<{value: boolean}>) => void) => void;
}

export interface MenuItem {
    text: string,
    link: string,
    icon?: React.ReactNode,
    activeIcon?: React.ReactNode
    target?: '_blank'|''
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: theme.sidebar?.width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    height: "100%"
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.sidebarClosed?.width,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`,
    },
    height: "100%"
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: theme.sidebar?.width,
        height: "100%",
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Sidebar = React.memo(({sidebarOpen, addSidebarEventListener, removeSidebarEventListener, updateSidebarOpen, ...rest}: SidebarProps) => {

    const location = useLocation();

    useEffect(() => {
        const cb: (e: CustomEvent<{value: boolean}>) => void = (e) => {
            if (e.detail.value !== sidebarOpen) {
                updateSidebarOpen(!sidebarOpen)
            }
        }

        addSidebarEventListener(cb)
        return () => {
            removeSidebarEventListener(cb)
        }
    })

    const svgWrapper = function (child: JSX.Element, {...rest}){
        return  <SvgIcon {...rest} viewBox={"0 0 16 18"} style={{minWidth: "16px", height: "18px"}}>
                    {child}
                </SvgIcon>
    }

    const menu: Array<MenuItem> = [
        /*{text: 'Dashboard', link: '/', icon: svgWrapper(<DashboardIcon/>, {}), activeIcon : svgWrapper(<DashboardFilledIcon/>, {})},*/
        {text: 'Video Encoding', link: '/encoding', icon: svgWrapper(<EncodingIcon/>, {}), activeIcon : svgWrapper(<EncodingFilledIcon/>, {})},
        {text: 'Statistics', link: '/stats', icon: svgWrapper(<StatisticsIcon/>, {}), activeIcon : svgWrapper(<StatisticsFilledIcon/>, {})},
        {text: 'Wallet', link: '/wallet', icon: svgWrapper(<WalletIcon/>, {}), activeIcon : svgWrapper(<WalletFilledIcon/>, {})},
    ]

    const menuBottom: Array<MenuItem> = [
        {text: 'Support', link: '/help', icon: svgWrapper(<HeadsetIcon/>, {}), activeIcon : svgWrapper(<HeadsetFilledIcon/>, {})},
        {text: 'Documentation', link: 'https://docs.bytenite.com', target: '_blank', icon: svgWrapper(<DocumentationIcon/>, {}), activeIcon : svgWrapper(<DocumentationFilledIcon/>, {})},
    ]

    return (
        <Drawer open={sidebarOpen} variant='permanent'  { ...rest}>
                {sidebarOpen
                    ? <Box mb={1} mt={5} ml={3} pl={2}>
                        <SvgIcon viewBox={"0 0 477.45 165.45"} style={{minWidth: "136px", height: "48px"}}>
                            <LogoExtended/>
                        </SvgIcon>
                      </Box>
                    : <Box mb={1} mt={5} display="flex" justifyContent="center">
                        <SvgIcon viewBox={"0 0 56 32"} style={{minWidth: "56px", height: "32px"}}>
                            <Logo/>
                        </SvgIcon>
                      </Box>
                }


            { sidebarOpen
                ?
                <Box mb={0} mr={3} mt={2} display="flex" justifyContent="flex-end" >
                    <span style={{cursor : "pointer"}}>{svgWrapper(<ChevronLeftIcon />, {onClick : () => updateSidebarOpen(false)})}</span>
                </Box>
                :
                <Box mb={0} mt={2} display="flex" justifyContent="center" style={{cursor : "pointer"}}>
                    <span style={{cursor : "pointer"}}>{svgWrapper(<ChevronRightIcon />, {onClick : () => updateSidebarOpen(true)})}</span>
                </Box>
            }

            <Box sx={{height: "100%", display:'flex', justifyContent:'space-between', flexDirection:'column'}}>
            <Box sx={sidebarOpen ? {overflow: 'display'} :
                {overflow: 'display', display:'flex', justifyContent:'center'}}
                 my={2} ml={sidebarOpen ? 3 : 0}>
                <List sx={{padding:0}}>
                    {menu.map(({text, link, icon=null, activeIcon=null}) =>
                        <ListItemLink to={link} key={text} primary={text} icon={icon} activeIcon={activeIcon} active={location.pathname === link}
                                      color="primary.contrastText"
                                      showText={sidebarOpen}
                        />
                    )}
                </List>
            </Box>

            <Box mb={12.5} mt={2} ml={sidebarOpen ? 3 : 0}
                 sx={sidebarOpen ? {} : {display:'flex', justifyContent:'center'}}>
                <List sx={{padding:0}}>
                    {menuBottom.map(({text, link, icon=null, activeIcon=null, target}) =>
                        <ListItemLink to={link} target={target} key={text} primary={text} icon={icon} activeIcon={activeIcon} active={location.pathname === link}
                                      color="secondary.contrastText"
                                      showText={sidebarOpen}
                        />
                    )}
                </List>
            </Box>
            </Box>
        </Drawer>);
})

export default Sidebar;
