import * as React from 'react';
import {Box, Typography} from '@mui/material';
import Paper, {PaperProps} from '@mui/material/Paper';
import "../../../styles/sass/containers.scss"

export interface CardStatsSmallProps extends PaperProps {
    title: string;
    value: number;
    measureUnit: any;
}

const CardSmall = ({...props}: CardStatsSmallProps) => {
    const {title, value, measureUnit, color} = props
    return (
        <Paper elevation={3}>
            <Box px={3} py={4}>
                <div className={"container-horizontal content-space-between"}>
                    <Box mt={2}>
                        <Typography variant={"chip"} color={color}>{title}</Typography>
                    </Box>
                    <Box>
                        <div className={"container-horizontal content-space-between"}>
                            <Typography variant={"h1"} color={color}>{value}</Typography>
                            <Box ml={1} mt={2}>
                                <Typography variant={"chip"} color={color}>{measureUnit}</Typography>
                            </Box>
                        </div>
                    </Box>
                </div>
            </Box>
        </Paper>
    );
}

export default CardSmall
