import * as React from 'react';
import {Box, Typography} from '@mui/material';
import Paper, {PaperProps} from '@mui/material/Paper';
import "../../../styles/sass/containers.scss"
import {ReactComponent as TimerIcon} from "../../../assets/statsIcons/timer.svg";
import {ReactComponent as CoinBlankIcon} from "../../../assets/statsIcons/coin-blank.svg";
import {ReactComponent as VideoEncodedIcon} from "../../../assets/statsIcons/video-encoded.svg";
import {ReactComponent as CloudArrowUpIcon} from "../../../assets/statsIcons/cloud-arrow-up.svg";

export interface CardStatsSmallProps extends PaperProps {
    title: string;
    value: number;
    measureUnit: any;
    color: string;
    iconName: string;
}

const CardLarge = ({...props}: CardStatsSmallProps) => {
    const {title, value, measureUnit, color, iconName} = props
    let icon = <></>
    switch (iconName){
        case "encoded":
            icon = <VideoEncodedIcon></VideoEncodedIcon>
            break;
        case "upload":
            icon = <CloudArrowUpIcon></CloudArrowUpIcon>
            break;
        case "processing":
            icon = <TimerIcon></TimerIcon>
            break;
        case "bytechips":
            icon = <CoinBlankIcon></CoinBlankIcon>
            break;
        default:
            break
    }
    return (
        <Paper elevation={3}>
            <Box px={3} py={5}>
                <div className={"container-horizontal content-space-between"}>
                    <Box width={"30%"}>
                        {icon}
                    </Box>
                    <Box>
                        <div className={"container-vertical content-space-between"}>
                            <Box>
                                <Typography variant={"chip"} color={color}>{title}</Typography>
                            </Box>
                            <Box>
                                <div className={"container-horizontal content-end"}>
                                    <Typography variant={"h1"} color={color}>{value}</Typography>
                                    <Box ml={1} mt={2}>
                                        <Typography variant={"chip"} color={color}>{measureUnit}</Typography>
                                    </Box>
                                </div>
                            </Box>
                        </div>
                    </Box>
                </div>
            </Box>
        </Paper>
    );
}

export default CardLarge
