import {Alert, Box, BoxProps, Button, Chip, CircularProgress, Grid, TextField, Typography} from '@mui/material';

import {ReactComponent as ArrowRightLeft} from "../../assets/walletIcons/arrow-right-arrow-left.svg"
import {ReactComponent as ChipBlue} from "../../assets/walletIcons/chip-blue.svg"
import {ReactComponent as ChipPink} from "../../assets/walletIcons/chip-pink.svg"
import {ReactComponent as DollarSign} from "../../assets/walletIcons/dollar-sign.svg"
import {ReactComponent as ArrowRightWhite} from "../../assets/jobIcons/arrow-right-white.svg"
import {ReactComponent as ArrowRightGray} from "../../assets/jobIcons/arrow-right-gray.svg"
import React, {useEffect, useState} from 'react';
import PaymentForm from "./PaymentForm";

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

export type PaymentParams = {
    currencyAmount: number,
    amount: number,
    clientSecret: string,
    currency: string,
}

export type StripeSubscriptionFormProps = {
    box?: BoxProps,
    paymentParams: PaymentParams
}

const stripeKey = document.querySelector('meta[name="stripe-key"]')?.getAttribute('content')||'...'
if (!stripeKey) {
    throw new Error(`No stripe key (${stripeKey})`)
}
const stripePromise = loadStripe(stripeKey);

const StripeSubscriptionForm = ({box, paymentParams}: StripeSubscriptionFormProps) => {
    const boxProps = {...box}
    const {currencyAmount, currency, clientSecret} = paymentParams
    const [isReady, setIsReady] = useState(false)

    return (<Box className="StripeSubscriptionForm" {...boxProps} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
        {isReady ?
          <Box mb={1}>
              <Typography variant="h1" textAlign="center">{currencyAmount}</Typography>
              <Typography variant="body2" textAlign="center">{currency}/month</Typography>
          </Box> :
          <Box my={2}>
              <CircularProgress></CircularProgress>
          </Box>
        }
        <Box mt={2}>
            {clientSecret ?
              <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
                  <PaymentForm onReady={() => setIsReady(true)}/>
              </Elements>
              :null}
        </Box>
    </Box>)
}

export default StripeSubscriptionForm
