import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { withApi, ApiProviderProps } from "../hoc/ApiProvider/Provider";
import PageHeader from "@bytenite/components/src/components/layoutComponents/PageHeader";
import TransactionHistoryPaper from "@bytenite/components/src/components/displayDataComponents/wallet/TransactionHistory";
import WalletCard from "@bytenite/components/src/components/WalletCard";
import { ApiContextInterface, useApiContext } from "../hoc/ApiProvider/context";
import { TopUpParams } from "@bytenite/components/src/components/formComponents/TopUpForm";
import PromoCodeForm, { RedeemCouponResponse } from "@bytenite/components/src/components/formComponents/PromoCodeForm";
import SubscriptionCard, { SubscriptionCardRef } from "@bytenite/components/src/components/displayDataComponents/wallet/SubscriptionCard";
import { PricingPlan } from "@bytenite/components/src/types/utils";
import { BytenitewalletTransaction, PlanPlanType, WalletPlan, WalletSubscription } from "../client";
import { useModals } from "@bytenite/components/src/hoc/Modals/Provider";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentParams } from "@bytenite/components/src/components/formComponents/StripeForm";

import Redeem from "@bytenite/components/src/components/icons/Redeem";
import { ReactComponent as IconTopUp } from "@bytenite/components/src/assets/walletIcons/icon-top-up.svg"
import { ReactComponent as Xmark } from "@bytenite/components/src/assets/walletIcons/xmark.svg"

import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

interface WalletProps extends ApiProviderProps {

}

interface WalletBalance {
  stockBalance: number
}
const stripeKey = document.querySelector('meta[name="stripe-key"]')?.getAttribute('content') || '...'
if (!stripeKey) {
  throw new Error(`No stripe key (${stripeKey})`)
}
const stripePromise = loadStripe(stripeKey);
const Wallet: React.FC<WalletProps> = ({ }) => {
  const modals = useModals()
  const api = useApiContext()

  const [isLoading, setIsLoading] = useState(true)
  const [balance, setBalance] = useState<WalletBalance>({ stockBalance: 0 });
  const [transactionsHistory, setTransactionsHistory] = useState<BytenitewalletTransaction[]>([]);
  const [walletAccountId, setWalletAccountId] = useState<string>('');
  const [pendingTopUpId, setPendingTopUpId] = useState<string>('');
  const [subscriptionPlans, setSubscriptionPlans] = useState<PricingPlan[]>([]);
  const [stockPlans, setStockPlans] = useState<PricingPlan[]>([]);
  const [activeSubscription, setActiveSubscription] = useState<WalletSubscription | undefined>();
  const [promoCodeOpen, setPromoCodeOpen] = useState(false)

  const subscriptionRef = useRef<SubscriptionCardRef>();

  useEffect(() => {
    //stripePromise.then(s => s?.retrievePaymentIntent())
    if (!stripePromise) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      loadAll().then(() => {
        setIsLoading(false)
      })
      return;
    }


    let message = ''
    const modalContent = <Box mb={4}>
      <Typography variant="body1" textAlign="center">Your payment is being processed.</Typography>
      <Typography variant="body1" textAlign="center">You can check the status for this transfer in your Wallet dashboard.</Typography>
    </Box>
    stripePromise.then(stripe => {
      if (!stripe) {
        throw new Error("Stripe not loaded")
      }
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent?.status) {
          case "succeeded":
            message = "Payment succeeded!"
            break;
          case "processing":
            message = "Your payment is processing."
            break;
          case "requires_payment_method":
            message = "Your payment was not successful, please try again."
            break;
          default:
            message = "Something went wrong."
            break;
        }
        setIsLoading(true)
        modals?.OkOnly('Thank you!', modalContent, { iconHeader: true, size: 'xs', confirmText: 'close', disableTypography: true, variant: 'contained', alignButtons: 'center' }).finally(() => {
          //TODO: remove page reload
          window.location.href = `${window.location.origin}${window.location.pathname}`
        })
      })
    });
  }, []);

  if (!api || !modals) {
    return <></>
  }

  const loadAll = async () => {
    return Promise.all([getBalance(), getTransactionHistory(), loadPlans(), getActiveSubscription()])
  }

  const getBalance = () => api.getBalance().then(resp => {
    //resp.stockBalance
    const updatedBalance = {
      stockBalance: parseInt(resp.stockBalance || '0')
    }
    setBalance(updatedBalance);
  });

  const getTransactionHistory = () => api.getTransactionList().then(resp => {
    if (resp.transactions) {
      setTransactionsHistory(resp.transactions);
    }
    if (resp.accountId) {
      setWalletAccountId(resp.accountId);
    }
  });

  const getActiveSubscription = () => {
    return api.getActiveSubscription().then(resp => { setActiveSubscription(resp); return resp }).catch((e: Response) => {
      if (e.status && e.status === 404) {
        setActiveSubscription(undefined)
        return
      }
      //TODO: throw e
    })
  }
  const walletPlanToPricingPlan = (p: WalletPlan): PricingPlan => {
    return { label: p.amount || '0', value: parseInt(p.amount || '0'), currencyAmount: ((parseInt(p.amount || '0')) * (p.unitPrice || 0)), unitPrice: p.unitPrice || 0 }
  }

  const loadPlans = () => api.getPlans().then(resp => {
    setStockPlans(resp.filter(p => p.planType === PlanPlanType.STOCK).map(walletPlanToPricingPlan))
    setSubscriptionPlans(resp.filter(p => p.planType === PlanPlanType.SUBSCRIPTION).map(walletPlanToPricingPlan))
  });

  const onTopUpRequest = async (currency: string, amount: number): Promise<TopUpParams> => {
    const resp = await api.createTopUpRequest(currency, amount)
    setPendingTopUpId(resp.topUpRequest?.id || '');
    return {
      amount: parseFloat(resp.topUpRequest?.amount || '0'),
      currencyAmount: parseFloat(resp.topUpRequest?.currencyAmount || '0') / 100,
      clientSecret: resp.clientSecret || '',
      currency: resp.topUpRequest?.currency || 'USD'
    }
  }

  const onCancelTopUpRequest = (topUpRequestId: string) => {
    return api.cancelTopUpRequest(topUpRequestId, 'Canceled by user').then(resp => {
      if (resp.ok) {
        setPendingTopUpId('');
      }
    }).catch((err: any) => {
      console.error(err);
    });
  }

  const onPromoCodeRequest = async (code: string): Promise<RedeemCouponResponse> => {
    const resp = await api.redeemPromoCode(code)
    return {
      amount: parseFloat(resp.amount || '0'),
      code: resp.code || '',
      id: resp.id || '',
    }
  }

  const handleChangePlanType = (value?: number) => {
    subscriptionRef.current?.startSubscriptionPlan(value)
  }
  const handleSubscriptionClick = async () => {
    subscriptionRef.current?.startSubscriptionPlan(activeSubscription?.amount ? parseInt(activeSubscription?.amount) : undefined)
  }

  const onPromoCodeRedeemed = () => {
    getBalance();
    getTransactionHistory();
  }
  const onStartPlan = async (amount: number): Promise<PaymentParams> => {
    const resp = await api.createSubscription({ amount: amount.toString() })
    if (!resp.subscription || (!resp.clientSecret && !resp.paid)) {
      //TODO: "paid"
      throw new Error("invalid response")
    }
    return { amount: parseInt(resp.subscription?.amount || '0'), clientSecret: resp.clientSecret || '', currency: "USD", currencyAmount: parseInt(resp.subscription?.currencyAmount || '0') / 100 }
  }
  const onChangePlan = async (amount: number): Promise<PaymentParams> => {
    if (!activeSubscription?.id) {
      throw new Error("no active subscription")
    }
    const resp = await api.updateSubscription(activeSubscription.id, { amount: amount.toString() })
    if (!resp.subscription) {
      throw new Error("invalid response")
    }
    return { amount: parseInt(resp.subscription?.amount || '0'), clientSecret: '', currency: "USD", currencyAmount: parseInt(resp.subscription?.currencyAmount || '0') / 100 }
  }

  const onCancelSubscription = async () => {
    if (!activeSubscription?.id) {
      throw new Error("no active subscription")
    }
    await api.cancelSubscription(activeSubscription.id)
  }
  const subscriptionState = activeSubscription?.state ? activeSubscription.state.toString().toLowerCase().replace("_", " ") : "inactive"
  const nexBillingDate = activeSubscription?.nextRenewal ? new Date(activeSubscription?.nextRenewal) : undefined
  const lastTopUp = +(transactionsHistory.find(t => t.transactionType === 'top_up' || t.transactionType === 'gift')?.amount || 0)

  const walletLoader = async <T,>(fn: () => Promise<T>) => {
    setIsLoading(true)
    try {
      fn().finally(() => {
        return loadAll().finally(() => {
          setIsLoading(false)
        })
      })
    } catch (e) {
      //TODO: error modal
      console.error(e)
      setIsLoading(false)
    }
  }

  const handlePromoCodeClose = () => {
    setPromoCodeOpen(false)
    onPromoCodeRedeemed()
  }
  const handlePromoCodeOpen = () => {
    setPromoCodeOpen(true)
  }

  console.log("testing version 1.0.1")

  return (
    <Box>
      <PageHeader title={"Wallet"} buttons={[<Button startIcon={<Redeem size="small" color="primary" />} onClick={handlePromoCodeOpen}>Redeem</Button>]}></PageHeader>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8} display="flex">
          {isLoading ? <Skeleton width="100%" /> : <TransactionHistoryPaper
            balance={balance.stockBalance}
            transactions={transactionsHistory}
            accountId={walletAccountId}
          />}
        </Grid>
        <Grid item xs={4} md={4}>
          <Box ml={1} display="flex" flexDirection="column" gap={2}>
            {/*<Paper elevation={3}>
                <TopUpForm getExchangeRate={api.getExchangeRate} onTopUpRequest={onTopUpRequest} balance={balance}/>
            </Paper>*/}
            {isLoading ? <Skeleton height={608} /> : <WalletCard
              stockPlans={stockPlans}
              subscriptionPlans={subscriptionPlans}
              subscriptionStatus={subscriptionState}
              onChangePlanType={handleChangePlanType}
              onSubscriptionClick={handleSubscriptionClick}
              stockBalance={balance.stockBalance}
              subscriptionPlan={activeSubscription?.amount ? parseInt(activeSubscription?.amount) : 0}
              lastTopUp={lastTopUp || 0}
              onTopUpRequest={onTopUpRequest}
              onCancelTopUpRequest={onCancelTopUpRequest}
              pendingTopUpId={pendingTopUpId}
            />}
            {isLoading ?
              <Skeleton height={258} /> :
              <SubscriptionCard status={subscriptionState}
                ref={subscriptionRef}
                walletLoader={walletLoader}
                plan={activeSubscription?.amount ? parseInt(activeSubscription?.amount) : undefined}
                price={activeSubscription?.currencyAmount ? parseInt(activeSubscription?.currencyAmount) : undefined}
                pendingPayment={activeSubscription?.clientSecret}
                nextBillingDate={nexBillingDate}
                plans={subscriptionPlans} onStartPlan={onStartPlan} onChangePlan={onChangePlan} onUpdatePayment={() => null}
                onCancelSubscription={onCancelSubscription} />}
            <Box mb={4} />
          </Box>
        </Grid>
      </Grid>
      <Dialog onClose={handlePromoCodeClose} open={promoCodeOpen} PaperProps={{ className: "modal-top-up" }}>
        <div className={"icon-top-up"}>
          <IconTopUp />
        </div>
        <DialogContent dividers>
          <div className={"container-horizontal  content-end"}>
            <Button onClick={handlePromoCodeClose}><Xmark></Xmark></Button>
          </div>
          <PromoCodeForm onPromoCodeRequest={onPromoCodeRequest} balance={(balance?.stockBalance || 0)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Wallet;
