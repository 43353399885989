import React, {useState} from 'react';
import './_signin-page.scss'
import '@bytenite/components/src/styles/sass/containers.scss'

//social media icons
import {ReactComponent as LogoLogin} from "@bytenite/components/src/assets/bytenite-logo-payoff-white.svg";
import {ReactComponent as ArrowRightPurple} from "@bytenite/components/src/assets/arrow-right-purple.svg";
import {ReactComponent as ArrowRightGray} from "@bytenite/components/src/assets/arrow-right-gray.svg";
import {ReactComponent as ArrowLeftWhite} from "@bytenite/components/src/assets/jobIcons/arrow-left-white.svg";

import {useLocation, useNavigate} from "react-router-dom";
import {Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, Grid, InputLabel, SvgIcon, Tab, Tabs, Typography} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import OtpInput from "@bytenite/components/src/components/formComponents/otpInput/OtpInput";
import Layout from "../../components/Layout";
import {AuthTokenRequest} from "../../client";
import {useIdpContext} from '@bytenite/auth/src/hoc/Idp/context';
import {useAuthContext} from "../../hoc/Auth/context";
import {useEffectOnce} from "../../hooks/UseEffectOnce";
import {ReactComponent as LogoExtended} from "*.svg";

type AuthMode = 'signin' | 'signup';
const AUTH_MODE_STORAGE_KEY = "bytenite_auth_mode"

interface SigninPageProps {
  onComplete?: ({nonce, email}: { nonce: string; email: string }) => void;
  isSignUp?: boolean
}

interface LoginSignupFormProps {
  form: LoginForm;
  defaultAuthMode?: AuthMode;
  onAuthModeChange?: (authMode: AuthMode) => void;
  setForm: (form: LoginForm) => void;
  setNextDisabled: (disabled: boolean) => void
}

interface LoginForm {
  email: string;
  firstName?: string;
  lastName?: string;
  isNewUser?: boolean;
}

const SignUpForm: React.FC<{ form: LoginForm; setForm: (form: LoginForm) => void; setNextDisabled: (disabled: boolean) => void }> = ({
                                                                                                                                       form,
                                                                                                                                       setForm,
                                                                                                                                       setNextDisabled,
                                                                                                                                     }) => {

  const styleProps = {
    className: 'subvariant-white',
    inputProps: {
      className: 'subvariant-white',
    }
  }
  return <Grid spacing={2} container>
    <Grid item xs={6}>
      <FormControl fullWidth>
        <InputLabel className="subvariant-white">First Name</InputLabel>
        <OutlinedInput
          label="First Name"
          color="primary"
          autoComplete="off"
          {...styleProps}
          placeholder="First Name"
          value={form.firstName || ''}
          onChange={(e) => {
            setForm({...form, firstName: e.target.value});
            setNextDisabled(e.target.value === '');
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={6}>
      <FormControl fullWidth>
        <InputLabel className="subvariant-white">Last Name</InputLabel>
        <OutlinedInput
          label="Last Name"
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          inputProps={{
            className: 'subvariant-white',
          }}
          placeholder="Last Name"
          value={form.lastName || ''}
          onChange={(e) => {
            setForm({...form, lastName: e.target.value});
            setNextDisabled(e.target.value === '');
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel className="subvariant-white">Email</InputLabel>
        <OutlinedInput
          label="Email"
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          inputProps={{
            className: 'subvariant-white',
          }}
          placeholder="Insert your email"
          value={form.email}
          onChange={(e) => {
            setForm({...form, email: e.target.value});
            setNextDisabled(e.target.value === '');
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="caption" color="white">By continuing, you agree to ByteNite’s <a href="https://www.bytenite.com/legal/terms-of-use" target="_blank">Terms of Service</a> and <a href="https://www.bytenite.com/legal/privacy-policy" target="_blank">Privacy Policy</a></Typography>
    </Grid>
  </Grid>
};
const SignInForm: React.FC<{ form: LoginForm; setForm: (form: LoginForm) => void; setNextDisabled: (disabled: boolean) => void }> = ({
                                                                                                                                       form,
                                                                                                                                       setForm,
                                                                                                                                       setNextDisabled,
                                                                                                                                     }) => {

  const styleProps = {
    className: 'subvariant-white',
    inputProps: {
      className: 'subvariant-white',
    }
  }

  return <Grid spacing={2} container>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <InputLabel className="subvariant-white">Email</InputLabel>
        <OutlinedInput
          label="Email"
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          inputProps={{
            className: 'subvariant-white',
          }}
          placeholder="Insert your email"
          value={form.email}
          onChange={(e) => {
            setForm({...form, email: e.target.value});
            setNextDisabled(e.target.value === '');
          }}
        />
      </FormControl>
    </Grid>
  </Grid>
};
const LoginSignupForm: React.FC<LoginSignupFormProps> = ({
                                                           form,
                                                           setForm,
                                                           onAuthModeChange,
                                                           setNextDisabled,
                                                           defaultAuthMode,
                                                         }) => {
  const [authMode, setAuthMode] = React.useState(defaultAuthMode === 'signup' ? 1 : 0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== authMode) {
      setAuthMode(newValue);
      if (onAuthModeChange) {
        onAuthModeChange(newValue === 0 ? 'signin' : 'signup')
      }
    }
  };


  // borderBottom: 1, borderColor: 'divider',
  return (
    <Box sx={{maxWidth: '100vh', width: '286px'}} display="flex" flexDirection="column" alignItems="center">
      <Box sx={{width: '240px'}}>
        <Tabs value={authMode} onChange={handleChange} textColor="inherit" aria-label="Login or signup" sx={{justifyContent: 'space-between'}}>
          <Tab label="Log In"/>
          <Tab label="Sign Up"/>
        </Tabs>
      </Box>
      <Box role="tabpanel" sx={{width: '100%'}} hidden={authMode !== 0} id="tabpanel-login" pt={4}>
        <SignInForm form={form} setForm={setForm} setNextDisabled={setNextDisabled}/>
      </Box>
      <Box role="tabpanel" sx={{width: '100%'}} hidden={authMode !== 1} id="tabpanel-signup" pt={4}>
        <SignUpForm form={form} setForm={setForm} setNextDisabled={setNextDisabled}/>
      </Box>
    </Box>
  );
};

const VerificationStep: React.FC<{ email: string; validOtp: boolean; checkPin: () => void; setOtpValue: (value: string) => void }> = ({
                                                                                                                                        email,
                                                                                                                                        validOtp,
                                                                                                                                        checkPin,
                                                                                                                                        setOtpValue,
                                                                                                                                      }) => {

  // TODO: ? checkPin={checkPin}
  return (
    <div className="container-vertical content-center">
      <div className="container-horizontal content-center">
        <Typography color="white" variant="caption">
          {`We've sent an email with the verification code to ${email}`}
        </Typography>
      </div>

      <div className="container-horizontal content-center">
        <OtpInput color="primary" digits={6} setOtpValue={setOtpValue} isValid={validOtp}/>
      </div>
    </div>
  );
};

const TermsOfServiceStep: React.FC<{ acceptTerms: boolean; setAcceptTerms: (value: boolean) => void }> = ({
                                                                                                            acceptTerms,
                                                                                                            setAcceptTerms,
                                                                                                          }) => {
  return (
    <Box display="flex" flexDirection="column">
      <div className="container-horizontal content-center">
        <Typography color="white" variant="h3">
          Terms of service
        </Typography>
      </div>
      <Box mt={3}>
        <Checkbox className="subvariant-white" value={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)}/>
        <Typography color="white" variant="caption">
          I accept ByteNite’s
        </Typography>
        <Typography color="white" variant="caption">
          <b>
            <a style={{color: 'white'}} href="https://app.termly.io/document/terms-of-use-for-saas/78867ce3-ba1d-4196-94e0-fcf8f1776ea1" target="_blank">
              Terms of Use
            </a>
          </b>
        </Typography>
      </Box>
    </Box>
  );
};

const SigninPage: React.FC<SigninPageProps> = ({isSignUp = false}) => {
  const location = useLocation()
  const authCtx = useAuthContext()
  const queryParams = new URLSearchParams(location.search);

  const loginChallenge = queryParams.get('login_challenge');
  const authModeParam: AuthMode = ['signin', 'signup'].indexOf(queryParams.get('auth_mode')) !== -1 ?
    queryParams.get('auth_mode') as AuthMode : 'signin';
  const storedAuthMode = localStorage.getItem(AUTH_MODE_STORAGE_KEY) as AuthMode

  const [isLoading, setIsLoading] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [step, setStep] = useState(0);
  const [nonce, setNonce] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [validOtp, setValidOtp] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [checkingPin, setCheckingPin] = useState(false);
  const [respToken, setRespToken] = useState('');
  const [authMode, setAuthMode] = useState<AuthMode>(storedAuthMode || authModeParam);
  const [error, setError] = useState<string|undefined>();

  const [loginForm, setLoginForm] = React.useState<LoginForm>({email: '', isNewUser: authMode === 'signup'});

  const idpCtx = useIdpContext();
  const navigate = useNavigate();

  const checkPin = () => {
    setNextDisabled(true);
    setCheckingPin(true);
    const body: AuthTokenRequest = {email: loginForm.email, nonce: nonce, code: otpValue, deviceId: null, loginChallenge: loginChallenge}

    idpCtx.signInWithMail(body);
  };

  const requireOtp = (resend: boolean = false) => {
    return new Promise((resolve, reject) => {
      if (loginForm.email) {
        setNextDisabled(true);
        setCheckingPin(true);
        loginForm.isNewUser = (authMode === 'signup')
        idpCtx
          .requestSignInCode(loginForm)
          .then((resp) => {
            setNonce(resp.nonce);
            if (!resend) {
              setStep(step + 1);
            }
            setNextDisabled(false);
            setCheckingPin(false);
            resolve(resp.nonce)
          })
          .catch((err) => {
            setNextDisabled(false);
            setCheckingPin(false);
            reject(err)
          });
      } else {
        reject(new Error("email is required"))
      }
    })
  };

  const handleAuthModeChanged = (newAuthMode: AuthMode) => {
    setAuthMode(newAuthMode)
  }

  const resendEmail = () => {
    setNonce('')
    setIsLoading(true)
    requireOtp(true).finally(() => setIsLoading(false))
  }

  const nextStep = () => {
    switch (step) {
      case 0:
        requireOtp();
        setValidOtp(true);
        break;
      case 1:
        checkPin();
        break;
    }
  };

  useEffectOnce(() => {
    if (!loginChallenge) {
      authCtx.getLoginUrl().then((url) => {
        localStorage.setItem(AUTH_MODE_STORAGE_KEY, authModeParam)
        window.location.href = url
      }).catch((err) => {
        setError(err.message)
      })
    }
    if (step === 0 && loginChallenge) {
      localStorage.removeItem(AUTH_MODE_STORAGE_KEY)
      idpCtx.skipLogin(loginChallenge).then(resp => {
        if (resp.skip) {
          window.location.href = resp.redirectTo
        } else {
          setIsLoading(false)
        }
      }).catch((err) => {
        setError(err.toString())
        setIsLoading(false)
      })
    }
  }) //, [loginChallenge]

  const loadingComponent: React.JSX.Element = error?
    <Box display="flex" flexDirection="column" alignItems="center"> <Alert color="error"><AlertTitle>An error occurred</AlertTitle>{error}</Alert> </Box>:
    <Box display="flex" flexDirection="column" alignItems="center"><CircularProgress className="subvariant-white" size={56}/></Box>

  return (
    <Layout title="Computing platform">
      <Box mt={18} mb={3}>
        <div className="container-horizontal content-center">
          <SvgIcon style={{height: "148px", width: "auto"}}>
            <LogoLogin/>
          </SvgIcon>
        </div>
      </Box>
      {isLoading ? loadingComponent :
        <>
          <Box mt={3} mb={2} display="flex" flexDirection="column" alignItems="center">
            {step === 0 && <LoginSignupForm defaultAuthMode={authMode} form={loginForm} setForm={setLoginForm} setNextDisabled={setNextDisabled} onAuthModeChange={handleAuthModeChanged}/>}
            {step === 1 && <VerificationStep email={loginForm.email} validOtp={validOtp} checkPin={checkPin} setOtpValue={setOtpValue}/>}
            {step === 2 && <TermsOfServiceStep acceptTerms={acceptTerms} setAcceptTerms={setAcceptTerms}/>}
          </Box>
          <Box mb={5} mt={1}>
            <div className="container-horizontal content-center">
              {step > 0 && step < 2 && !checkingPin ? (
                <Box mx={1}>
                  <Button variant="outlined" className="subvariant-white" onClick={() => setStep(0)} size="large">
                    <Box mr={1}>
                      <ArrowLeftWhite/>
                    </Box>
                    <Typography>Previous</Typography>
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              {step < 2 && !checkingPin ? (
                <Box mx={1}>
                  <Button variant="outlined" disabled={nextDisabled} onClick={nextStep} size="large">
                    <Typography>Next</Typography>
                    <Box ml={1}>{nextDisabled ? <ArrowRightGray/> : <ArrowRightPurple/>}</Box>
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              {checkingPin && <CircularProgress className="subvariant-white" size={56}/>}
              {step === 2 ? (
                <Button variant="outlined" disabled={!acceptTerms} onClick={nextStep} size="large">
                  <Typography>Confirm</Typography>
                </Button>
              ) : (
                <></>
              )}
            </div>
            <Box display="flex" alignItems="center" mt={2} flexDirection="column">
              {step === 1 &&
                  <Typography color="white" variant="body2">Didn’t receive the email? <span onClick={() => resendEmail()} className="inline-link">Resend</span> </Typography>}
              {step === 1 && authMode === 'signup' &&
                  <Typography color="white" variant="body2">Have you already signed up? <span onClick={() => {
                    setAuthMode('signin')
                  }} className="inline-link">Sign in</span> </Typography>}
            </Box>
          </Box>
        </>
      }
    </Layout>
  );
}


export default SigninPage;
