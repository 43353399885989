import * as React from 'react';
import DisplayDataPaper from "../_displayDataPaper";

import {ReactComponent as FileIcon} from "../../../assets/jobIcons/file.svg";
import DisplayDataTable from "../_displayDataTable";
import PaginationData, {Pagination} from "../PaginationData";
import {useState} from "react";
import {Box} from "@mui/material";

const columnsOrder = ["dateTime", "description", "amount", "balance"]
const columnsNames = ["Date & Time", "Description", "Amount", "Balance"];

type TransactionType = "top_up" | "payout" | "payment" | "reward" | "refund" | "fee" | "gift" | "coupon";

export interface TransactionHistoryRecord {
  dateTime: string;
  id: string;
  description: string;
  amount: number;
  balance: number;
  transactionType?: TransactionType;
}

export interface TransactionHistoryPaperProps {
  transactions: any[];
  //isLoading : boolean;
  setTransactionsFilters?: any;
  accountId: string;
  balance: number;
  pageSize?: number
}

const parseTransactionsHistoryRecords = (transactions: any[], accountId: string, balance: number) => {
  let totBalance = balance;
  let amount = 0;
  const parsedTransactions: TransactionHistoryRecord[] = transactions.map(t => {
      const positive = t.accountTo === accountId;
      totBalance -= amount; //we use it as previous amount
      amount = +t.amount * (positive ? 1 : -1);
      let description = positive ? "Top up" : "Job"
      switch (t.transactionType) {
        case "gift":
          description = 'Gift'
          break
        case "coupon":
          description = 'Promo code'
          break
        case "reward":
          description = 'Computing reward'
          break
        case "refund":
          description = 'Refund'
          break
        case "payout":
          description = 'Payout'
          break
        case "subscription":
          description = 'Subscription'
          break
      }
      return {
        dateTime: t.createdAt ? (t.createdAt.split("T")[0] + ", " + t.createdAt.split("T")[1].split(".")[0]) : "",
        id: t.id,
        description: description, //TODO
        amount: amount,
        balance: totBalance
      }
    }
  );
  return parsedTransactions
}

const TransactionHistoryPaper = ({pageSize=10, ...props}: TransactionHistoryPaperProps) => {

  const {transactions, accountId, balance} = props;
  const [offset, setOffset] = useState(0)
  // const [limit, setLimit] = useState(10)

  const handlePageNumberClick = (page: number) => {
    setOffset((page-1) * pageSize)
  }

  const paginationData: Pagination = {
    hasNext: offset <= (transactions.length + pageSize),
    hasPrevious: offset > 0,
    offset: offset,
    total: transactions.length
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <DisplayDataPaper
        title={"Transaction history"}
        style={{height: '100%'}}
        headerActions={<></>}
        children={
          <DisplayDataTable data={parseTransactionsHistoryRecords(transactions, accountId, balance)}
                            columnsNames={columnsNames}
                            columnsOrder={columnsOrder}
                            transactionColumns={["amount"]}
                            iconNoData={<FileIcon></FileIcon>}
                            displayTableIfNoData={true}
                            messageNoData={"No transactions so far"} pagination={paginationData}/>
        }
      ></DisplayDataPaper>
      <PaginationData limit={pageSize} pagination={paginationData} onPageNumberClick={handlePageNumberClick}/>
    </Box>
  );
}

export default TransactionHistoryPaper
