import React, {forwardRef, useImperativeHandle, useState} from 'react';

// Importing Material UI components
import {Card, CardContent, Typography, Box, TableRow, TableCell, Table, Button, TableBody} from '@mui/material';

// Importing Custom Icons
import ByteChip from "../../icons/ByteChip";
import Plus from "../../icons/Plus";
import CreditCard from "../../icons/CreditCard";
import Cancel from "../../icons/Cancel";
import Edit from "../../icons/Edit";

// Importing Higher Order Component
import withModals, {useModals} from "../../../hoc/Modals/Provider";

// Importing Types
import {ModalContextType} from "../../../hoc/Modals/context";

// Importing Wallet Components
import CreateSubscription from "../../CreateSubscription";
import SubscriptionCancelFlow from "./SubscriptionCancelFlow";
import DisplayDataPaper from "../_displayDataPaper";
import {Option, PricingPlan} from "../../../types/utils";
import {PaymentParams} from "../../formComponents/StripeSubscriptionForm";
import CreateSubscriptionForm from "../../formComponents/CreateSubscriptionForm";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import T from "@bytenite/customer-ui/src/pages/WalletPage";
import walletLoader from "@bytenite/customer-ui/src/pages/WalletPage";


export interface SubscriptionCardProps {
    status: string,
    plans: PricingPlan[],
    plan?: number,
    price?: number,
    nextBillingDate?: Date,
    paymentMethod?: string,
    pendingPayment?: string
    onStartPlan: (plan: number) => Promise<PaymentParams>,
    onChangePlan?: (plan: number) => Promise<PaymentParams>,
    onUpdatePayment?: () => void,
    onCancelSubscription?: () => Promise<void>,
    walletLoader: <T>(fn: () => Promise<T>) => Promise<void>
}

interface CancelSubscriptionConfirmationProps {
    plan: number;
    price: number;
    expirationDate?: Date;
}

export interface SubscriptionCardRef {
    startSubscriptionPlan: (value?: number) => void
}

const CancelSubscriptionConfirmation: React.FC<CancelSubscriptionConfirmationProps> = ({plan, price, expirationDate}) => {

    return <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%" p={0}>
        <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1" textAlign="center">Your auto-renewal has been canceled.</Typography>
            <Typography variant="body1" textAlign="center">For any question or assistance, please contact us</Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" my={3}>
            <SubscriptionPlanCard title="Current plan" plan={plan} price={price}/>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
            <Typography variant="body1" color="error">Expiration date: {expirationDate ? expirationDate.toLocaleDateString() : '-'} </Typography>
        </Box>
    </Box>
}

/**
 * The `SubscriptionCard` component is used to display and manage user's subscription details.
 *
 * Props:
 * - `status`: A string indicating the current status of the subscription.
 * - `plan`: (Optional) The current plan number the user is subscribed to.
 * - `price`: (Optional) The price of the current plan.
 * - `nextBillingDate`: (Optional) The next billing date for the current plan.
 * - `paymentMethod`: (Optional) The payment method being used for the subscription.
 * - `onStartPlan`: A callback function to handle the action when the user wants to start a plan.
 * - `onChangePlan`: A callback function to handle the action when the user wants to change their current plan.
 * - `onUpdatePayment`: A callback function to handle the action when the user wants to update their payment method.
 * - `onCancelSubscription`: A callback function to handle the action when the user wants to cancel their subscription.
 * - `modals`: An object representing the modal context type, used for opening and closing modals.
 *
 * This component maintains its own internal state for whether a subscription operation is pending.
 * It defines several handlers for starting, changing, and cancelling subscriptions, and for updating payment methods.
 * The component renders a card-style UI that displays current subscription information and provides
 * various actions the user can take.
 */
//: React.FC<SubscriptionCardProps>
const SubscriptionCard = forwardRef(({
                                                                                              plans,
                                                                                              status,
                                                                                              plan,
                                                                                              price,
                                                                                              nextBillingDate,
                                                                                              paymentMethod,
                                                                                              pendingPayment,
                                                                                              onStartPlan,
                                                                                              onChangePlan,
                                                                                              onUpdatePayment,
                                                                                              onCancelSubscription,
                                                                                              walletLoader
                                                                                          }: SubscriptionCardProps, ref) => {

    const cardStyle = {
        minWidth: '300px'
    }

    const modals = useModals()

    if (!modals) {
        return <></>
    }

    const [isPending, setIsPending] = useState(false)

    const subscriptionsEnabled: boolean = !!onStartPlan && !!onChangePlan && !!onUpdatePayment && !!onCancelSubscription


    const handleSubscriptionFlow = async (currentPlan?: number, defaultPlan?: number) => {
        const isUpdate = !!currentPlan
        if (!onChangePlan || !onStartPlan) {
            //TODO: error modal
            console.error('Subscriptions not enabled')
            return
        }
        //pendingPayment
        const pendingPaymentParams: PaymentParams | undefined = pendingPayment ? {amount: plan || 0, clientSecret: pendingPayment, currency: "USD", currencyAmount: ((price || 0)/100)} : undefined
        try {
            const selectAmountResult = await modals.Custom<{ value?: number }>('Subscribe', (closeModal: (ok: boolean, data: { value?: number }) => void) => <CreateSubscriptionForm
                plans={plans}
                isUpdate={isUpdate}
                currentPlan={currentPlan||defaultPlan}
                pendingPaymentParams={pendingPaymentParams}
                nextBillingDate={nextBillingDate ? nextBillingDate.toDateString() : undefined}
                onUpdateConfirm={(value) => closeModal(true, {value})}
                onSubscriptionRequest={(value) => isUpdate ? onChangePlan(value) : onStartPlan(value)}/>, {iconHeader: true, size: isUpdate ? 'sm': 'xs'})
            if (!selectAmountResult.ok || !selectAmountResult.data?.value) {
                return
            }
            const selectedAmount = selectAmountResult.data.value
            if (isUpdate) {
                return new Promise((resolve) => setTimeout(resolve, 3000))
            } else {
                return onStartPlan(selectedAmount)
            }
        } catch (e) {
            console.error('Subscription flow canceled', e)
        }
    }

    const handleUnsubscribeFlow = async (currentPlan: number) => {
        const planObj = plans.find(p => p.value === currentPlan)
        if (!planObj) {
            //TODO: modals.error
            await modals.OkOnly('An error occurred', 'An error occurred while trying to cancel your subscription, please contact support', {size: 'xs'})
            return
        }
        //TODO: nextBillingDate
        try {
            const cancelFlowResult = await modals.Custom<{ canceled?: boolean }>('Unsubscribe', (closeModal) =>
                    (<SubscriptionCancelFlow onUnsubscribe={() => closeModal(true, {canceled: true})}
                                             onChangePlan={() => closeModal(true, {canceled: false})}
                                             nextBillingDate={nextBillingDate}
                                             plan={planObj.value}
                                             price={planObj.currencyAmount}/>),
                {iconHeader: true, size: 'xs'})
            if (!cancelFlowResult.ok) {
                return
            }
            if (!cancelFlowResult.data?.canceled) {
                return await handleSubscriptionFlow(currentPlan)
            }
            if (onCancelSubscription) {
                await onCancelSubscription()
                await modals.OkOnly('Sorry to see you go!', <CancelSubscriptionConfirmation plan={plan || 0} price={price || 0}
                                                                                            expirationDate={nextBillingDate}/>, {iconHeader: true, size: 'xs', alignButtons: 'center', variant: 'contained'})
                return new Promise((resolve) => setTimeout(resolve, 3000))
            }
        } catch (e) {
            console.error('Subscription flow canceled', e)
            await modals.OkOnly('An error occurred', 'An error occurred while trying to cancel your subscription, please contact support', {size: 'xs'})
        }
    }

    const handleStartPlan = (defaultPlan?: number) => {
        setIsPending(true)
        return walletLoader(async () => {
            return await handleSubscriptionFlow(undefined, defaultPlan).finally(() => setIsPending(false))
        })
    }

    const handleChangePlanClick = () => {
        setIsPending(true)
        return walletLoader(async () => {
            return await handleSubscriptionFlow(plan).finally(() => setIsPending(false))
        })
    }
    const handleUnsubscribeClick = () => {
        if (status === 'incomplete' && onCancelSubscription) {
            setIsPending(true)
            return walletLoader(async () => {
                return await onCancelSubscription().finally(() => setIsPending(false))
            })
        }
        if (plan) {
            setIsPending(true)
            return walletLoader(async () => {
                return await handleUnsubscribeFlow(plan).finally(() => setIsPending(false))
            })

        }
    }

    const handlePayPendingClick = () => {
        if (plan && pendingPayment) {
            setIsPending(true)
            return walletLoader(async () => {
                return await handleSubscriptionFlow(plan).finally(() => setIsPending(false))
            })
        }
    }

    useImperativeHandle(ref, (): SubscriptionCardRef => ({
        startSubscriptionPlan(value?: number) {
            handleSubscriptionFlow(value)
        },
    }));

    return (
        <DisplayDataPaper title="Your subscription">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{width: '100%'}}>
                <Box display="flex" flexDirection="row" justifyContent="center" px={5} alignSelf="stretch">
                    <Table size="compact" style={{width: '100%', flexGrow: 1}}>
                        <TableBody>
                            <TableRow><TableCell>Status</TableCell><TableCell align="right"><Typography variant="caption"
                                                                                                        color={status === 'active' ? 'success.main' : 'error.main'}>{status}</Typography></TableCell></TableRow>
                            <TableRow><TableCell>Plan</TableCell><TableCell align="right"><Typography variant="caption"><ByteChip translateY="4px" size="xs"
                                                                                                                                  style={{transform: 'translateY(5px)'}}/> {plan || '-'}
                            </Typography></TableCell></TableRow>
                            <TableRow><TableCell>Price</TableCell><TableCell align="right"><Typography
                                variant="caption">{price ? `$ ${(price / 100).toFixed(2)}` : '-'}</Typography></TableCell></TableRow>
                            <TableRow><TableCell>Next Billing Date</TableCell><TableCell align="right"><Typography
                                variant="caption">{nextBillingDate ? nextBillingDate.toLocaleDateString() : '-'}</Typography></TableCell></TableRow>
                            <TableRow><TableCell>Payment Method</TableCell><TableCell align="right"><Typography
                                variant="caption">{paymentMethod || '-'}</Typography></TableCell></TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" my={1}>
                    {status === 'inactive' &&
                        <Button variant="text" color="primary" disabled={isPending || !subscriptionsEnabled} onClick={() => handleStartPlan()}><Plus
                            color={isPending ? "disabled" : "primary"} size="small"/> Start Plan</Button>}
                    {status === 'active' &&
                        <Button variant="text" color="primary" disabled={isPending || !plan || !subscriptionsEnabled} onClick={() => handleChangePlanClick()}><Edit
                            color={isPending ? "disabled" : "primary"} size="small"/> Change
                            Plan</Button>}
                    {status === 'active' && <Button variant="text" color="primary" disabled={isPending || !plan || !subscriptionsEnabled}
                                                    onClick={() => onUpdatePayment && onUpdatePayment()}><CreditCard
                        color={isPending ? "disabled" : "primary"} size="small"/> Update Payment</Button>}
                    {status === 'incomplete' &&
                        <Button variant="text" color="primary" disabled={isPending || !plan || !subscriptionsEnabled} onClick={handlePayPendingClick}>
                            <CreditCard color={isPending ? "disabled" : "primary"} size="small"/> Pay
                        </Button>
                    }
                    {(status === 'active' || status === 'incomplete') &&
                        <Button variant="text" color="error" disabled={isPending || !plan || !subscriptionsEnabled} onClick={() => handleUnsubscribeClick()}><Cancel
                            color={isPending ? "disabled" : "error"} size="small"/> Cancel Subscription</Button>}
                </Box>
            </Box>
        </DisplayDataPaper>
    );
});


export default SubscriptionCard;
