import * as React from 'react';
import {Grid} from '@mui/material';
import {PaperProps} from '@mui/material/Paper';
import "../../../styles/sass/containers.scss"
import CardLarge from "./CardLarge";
import CardSmall from "./CardSmall";
import {ReactComponent as ChipBlueIcon} from "../../../assets/walletIcons/chip-blue.svg";

export interface DisplayDataPaperProps extends PaperProps {
    data : any
}

const StatsOverview = ({...props}: DisplayDataPaperProps) => {
    const {data} = props
    return (
        <>{data && <Grid container spacing={2}>
            <Grid item xs={3}>
                <CardLarge title={"Videos encoded"} value={+data.numVideosEncoded} measureUnit={""} color={"primary.A200"} iconName={"encoded"}></CardLarge>
            </Grid>
            <Grid item xs={3}>
                <CardLarge title={"Upload volume"} value={(+data.uploadVolume)/1000} measureUnit={"GB"} color={"secondary"} iconName={"upload"}></CardLarge>
            </Grid>
            <Grid item xs={3}>
                <CardLarge title={"Processing time"} value={+data.processingTime} measureUnit={"min"} color={"info.A200"} iconName={"processing"}></CardLarge>
            </Grid>
            <Grid item xs={3}>
                <CardLarge title={"BiteChips spent"} value={+data.bytechipsSpent} measureUnit={<ChipBlueIcon></ChipBlueIcon>} color={"secondary.dark"} iconName={"bytechips"}></CardLarge>
            </Grid>
            <Grid item xs={3}>
                <CardSmall title={"Encoded"} value={+data.minVideosEncoded} measureUnit={"min"} color={"primary.A200"}></CardSmall>
            </Grid>
            <Grid item xs={3}>
                <CardSmall title={"Compressed"} value={(+data.compressedVolume)/1000} measureUnit={"GB"} color={"secondary"}></CardSmall>
            </Grid>
            <Grid item xs={3}>
                <CardSmall title={"Time saved"} value={+data.timeSaved} measureUnit={"min"} color={"info.A200"}></CardSmall>
            </Grid>
            <Grid item xs={3}>
                <CardSmall title={"Purchased"} value={+data.bytechipsPurchased} measureUnit={<ChipBlueIcon></ChipBlueIcon>} color={"secondary.dark"}></CardSmall>
            </Grid>
        </Grid>}</>
    );
}

export default StatsOverview
