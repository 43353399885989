import React from 'react';
import {useTheme} from "@mui/material/styles";
import Icon, {SvgIconProps} from "./Icon";


const EditIcon: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
  return <Icon translateY="-2px" viewBox="0 0 18 18" path="M12.6094 8.91406L12.0703 9.45312L7.40625 14.1172C7.17188 14.375 6.86719 14.5391 6.53906 14.6562L3.70312 15.4766C3.51562 15.5234 3.30469 15.4766 3.16406 15.3359C3 15.1953 2.95312 14.9844 3.02344 14.7734L3.84375 11.9609C3.9375 11.6328 4.125 11.3281 4.35938 11.0703L9.02344 6.40625L9.5625 5.89062L9.82031 5.60938L10.6172 6.40625L12.0703 7.85938L12.8672 8.65625L12.6094 8.91406ZM6.75 12.875H6C5.78906 12.875 5.625 12.7109 5.625 12.5V11.75L5.08594 11.9609C5.01562 12.0547 4.94531 12.1484 4.92188 12.2656L4.38281 14.0938L6.21094 13.5547C6.32812 13.5312 6.42188 13.4844 6.51562 13.4141L6.75 12.875ZM11.4844 3.94531C12.0703 3.35938 13.0312 3.35938 13.6172 3.94531L14.5312 4.88281C15.1172 5.46875 15.1172 6.40625 14.5312 6.99219L14.2031 7.34375L13.6641 7.85938L13.4062 8.11719L12.6094 7.34375L11.1562 5.86719L10.3594 5.09375L10.6172 4.8125L11.1562 4.29688L11.4844 3.94531ZM10.3828 7.88281C10.5234 7.74219 10.5234 7.50781 10.3828 7.36719C10.2422 7.20312 9.98438 7.20312 9.84375 7.36719L6.46875 10.7422C6.32812 10.8828 6.32812 11.1172 6.46875 11.2578C6.60938 11.3984 6.86719 11.3984 7.00781 11.2578L10.3828 7.88281Z"
               {...props}/>
});

export default EditIcon;
