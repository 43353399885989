import React, { useState } from 'react';
import {FormControl, FormLabel, TextField} from "@mui/material";
import './_otp-input.scss'

interface OtpInputProps {
    color?: 'primary' | 'secondary';
    digits?: number;
    //size?: 'md';
    disabled?: boolean;
    label?: string;
    isValid?: boolean;
    setOtpValue?: (value: string) => void;
}

export default function OtpInput({
                                     color = 'primary',
                                     digits = 6,
                                     //size = 'md',
                                     disabled = false,
                                     label,
                                     isValid = true,
                                     setOtpValue,
                                 }: OtpInputProps) {
    const lastDigitIdx = digits - 1;
    const pin = Array(digits).fill(null);
    const pinInputs = Array(digits).fill(null) as HTMLInputElement[];

    const [isSelected, setIsSelected] = useState(false);

    const updatePin = () => {
        for (let i = 0; i < pinInputs.length; i++) {
            pin[i] = pinInputs[i].value ? pinInputs[i].value : null;
        }
    };

    const onDigitChange = (i: number, ev: React.ChangeEvent<HTMLInputElement>) => {
        let val = ev.target?.value;
        const valDeleted = !val;
        setIsSelected(true);
        if (val.length > 1) {
            const inputEvent = ev.nativeEvent as InputEvent;
            if (inputEvent && inputEvent.data !== '') {
                val = inputEvent.data||'';
            } else {
                val = val.length > 1 ? val[val.length - 1] : val;
            }
            pinInputs[i].value = val;
        }

        pin[i] = !valDeleted ? val : null;
        const mergedPin = pin.map((v) => (v ? v.toString() : '-')).join('');
        if (pin.filter((p) => p == null).length === digits) {
            setIsSelected(false);
        }

        setOtpValue && setOtpValue(mergedPin);

        // Focus next box if is not the last
        if (i < lastDigitIdx && !valDeleted) {
            pinInputs[i + 1].focus();
        }
    };

    const onKeyPressed = (i: number, ev: React.KeyboardEvent<HTMLInputElement|HTMLDivElement>) => {
        updatePin();
        switch (ev.key) {
            case 'ArrowLeft':
                if (i > 0) {
                    pinInputs[i - 1].select();
                }
                break;
            case 'ArrowRight':
                if (i < lastDigitIdx) {
                    pinInputs[i + 1].select();
                }
                break;
            case 'Backspace':
                if (i > 0) {
                    pinInputs[i].value = '';
                    pin[i] = null;
                    pinInputs[i - 1].select();
                }
                break;
        }
    };

    const computedClassName = disabled ? 'disabled' : isSelected ? (isValid === undefined ? 'selected' : (isValid ? '' : 'invalid')) : '';

    const numberBox = (i: number) =>
        <div className="number-box" key={`number-box-container-${i}`}>
            <TextField
              color={color}
              disabled={disabled}
              key={`number-box-${i}`}
              onSelect={() => {
                  setIsSelected(true);
              }}
              onKeyUp={(ev) => onKeyPressed(i, ev)}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onDigitChange(i, ev)}
              inputProps={{ className: computedClassName, maxLength:1, min:0, max:9, step:1 }}
              inputRef={(input: HTMLInputElement) => {
                  pinInputs[i] = input;
              }}
              type="number"/>
        </div>
    return (
        <FormControl>
            {label && <FormLabel className={'label-otp ' + computedClassName}>
                {label}
            </FormLabel>}
            <div className="otp-input">
                {pin.map((_, i) => numberBox(i))}
            </div>
            {isValid === false && (<span className='message-invalid-otp'>the verification code is not correct</span>)}
        </FormControl>
    );
}
