import {Alert, Box, BoxProps, Button, Typography} from '@mui/material';

import React, {useState} from 'react';

import {loadStripe} from "@stripe/stripe-js";
import CreateSubscription from "../CreateSubscription";
import StripeForm, {PaymentParams} from "./StripeForm";
import {PricingPlan} from "../../types/utils";
import StripeSubscriptionForm from "./StripeSubscriptionForm";
import ByteChip from '../icons/ByteChip';

export type SubscriptionParams = {
  currencyAmount: number,
  amount: number,
  clientSecret: string,
  currency: string
}

export type CreateSubscriptionFormProps = {
  box?: BoxProps,
  onSubscriptionRequest: (amount: number) => Promise<PaymentParams>,
  onUpdateConfirm: (value: number) => void,
  plans: PricingPlan[]
  currentPlan?: number | undefined
  isUpdate?: boolean
  pendingPaymentParams? : PaymentParams
  nextBillingDate?: string
}


const stripeKey = document.querySelector('meta[name="stripe-key"]')?.getAttribute('content') || '...'
if (!stripeKey) {
  throw new Error(`No stripe key (${stripeKey})`)
}
loadStripe(stripeKey);

const CreateSubscriptionForm = ({box, onSubscriptionRequest, onUpdateConfirm, plans, currentPlan, isUpdate, pendingPaymentParams, nextBillingDate}: CreateSubscriptionFormProps) => {
  const boxProps = {...box}
  const [currentStep, setCurrentStep] = useState(pendingPaymentParams ? 1 : 0)
  const [paymentParams, setPaymentParams] = useState<PaymentParams | undefined>(pendingPaymentParams)
  const [newSubscriptionValue, setNewSubscriptionValue] = useState<number | undefined>()

  const handleConfirm = (value: number) => {
    if (value) {
      onSubscriptionRequest(value).then(subscriptionParams => {
        if (isUpdate) {
          setNewSubscriptionValue(value)
          // onUpdateConfirm(value)
          setCurrentStep(2)
          //TODO: update logic
        } else {
          setPaymentParams(subscriptionParams)
          setCurrentStep(1)
        }
      })
    }
  }

  const handleClose = () => {
    if (newSubscriptionValue) {
      onUpdateConfirm(newSubscriptionValue)
    }
  }

  const newPlan = newSubscriptionValue ? plans.find(p => p.value === newSubscriptionValue) : undefined
  const selectedPlan = plans.find(p => p.value === currentPlan)
  const amountDiff = ((newPlan?.currencyAmount || 0) - (selectedPlan?.currencyAmount || 0)) / 100;
  const today = new Date()
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toDateString()

  const isDowngrade = amountDiff < 0


  switch (currentStep) {
    case 0:
      return <CreateSubscription variant="subscription" plans={plans} currentPlan={currentPlan} isUpdate={isUpdate} nextBillingDate={nextBillingDate} onConfirm={handleConfirm}/>
    case 1:
      if (paymentParams) {
        return <StripeSubscriptionForm paymentParams={paymentParams}/>
      }
      break
    case 2:
      if (isDowngrade) {
        return <Box>
          <Typography sx={{mb: 2}} variant="body1">Your current subscription of <ByteChip translateY="3px" size="xs"/> <strong>{currentPlan}</strong> will expire
            on <strong>{endOfMonth}</strong>.</Typography>
          <Typography sx={{mb: 2}} variant="body1">Your new subscription of <ByteChip translateY="3px" size="xs"/> <strong>{newSubscriptionValue}</strong> will begin immediately after,
            and will be billed on a monthly basis.</Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button variant="contained" color="primary" onClick={handleClose}>Ok</Button>
          </Box>
        </Box>
      }
      return <Box>
        <Typography sx={{mb: 2}} variant="body1">Your subscription for <ByteChip translateY="3px" size="xs"/> <strong>{newPlan?.value}</strong> will begin now, and you will be
          charged <strong>${amountDiff.toFixed(2)}</strong> upfront (difference
          in plans).</Typography>
        <Typography sx={{mb: 2}} variant="body1">From <strong>{endOfMonth}</strong>, you will be
          charged <strong>${((newPlan?.currencyAmount || 0) / 100).toFixed(2)}</strong> monthly.</Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button variant="contained" color="primary" onClick={handleClose}>Ok</Button>
        </Box>
      </Box>
  }
  return (<Box className="CreateSubscriptionForm" {...boxProps} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
    <Alert color="error">An error occurred</Alert>
  </Box>)
}

export default CreateSubscriptionForm
