import React, {useContext, useEffect, useRef, useState} from 'react';
import Typography from "@mui/material/Typography";
import PageHeader from "@bytenite/components/src/components/layoutComponents/PageHeader";
import {withApi} from "../hoc/ApiProvider/Provider";
import StatsOverview from "@bytenite/components/src/components/displayDataComponents/stats/StatsOverview";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AuthContext from "@bytenite/auth/src/hoc/Auth/context";

const Stats = (props) => {

    const [statsData, setStatsData] = useState(undefined)

    const navigate = useNavigate();
    const authCtx = useContext(AuthContext)

    const createNewJob = () => {
        props.api.createNewJob().then( (newJob) => {
            if(newJob && newJob.id){
                navigate(`/jobs/${newJob.id}`)
            }
        }).catch(err => {
            if (err.message.endsWith('auth.not_authenticated')) {
                navigate(authCtx.loginUrl)
            }
        })
    }

    useEffect(() => {
        const endDate = new Date(Date.now())
        const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) //30 days in milliseconds
        props.api.getStatsOverview(startDate, endDate).then(r => setStatsData(r))
    }, [])

    return (
        <Box>
            <PageHeader title={"Statistics"} createNewJob={createNewJob}></PageHeader>
            <Box mt={-3} mb={3}>
                <Typography variant={"h3"} color={"secondary.dark"}>This month's overview</Typography>
            </Box>
            <StatsOverview data={statsData}></StatsOverview>
        </Box>);
}

export default withApi(Stats);