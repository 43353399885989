import {Box, CardContent, CardProps, Divider, Paper, Typography} from "@mui/material";
import React from "react";
import Circle from "../../icons/Circle";
import ByteChip from "../../icons/ByteChip";

export interface SubscriptionPlanCardProps extends CardProps {
    title: string;
    plan?: number;
    price?: number;
    width?: number | string;
}

declare module '@mui/material' {
    interface SvgIconPropsSizeOverrides {
        xl: true;
        xxl: true;
    }
}

const SubscriptionPlanCard = ({title, plan, price, width=200, ...props}: SubscriptionPlanCardProps) => {

    return <Paper sx={{width: width }} {...props}>
        <CardContent sx={{padding:0, paddingBottom:'0!important', height: 180}}>
            <Box display="flex" justifyContent="center"  alignItems="center" flexDirection="column" height="100%" p={0}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Circle color="blue" size="small" style={{marginRight: '5px'}}/>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                </Box>
                <Box textAlign="center">
                    <Typography variant="h1" color="secondary.dark">
                        <ByteChip size="medium" translateY="5px"/> {plan}
                    </Typography>
                </Box>
                <Box sx={{width: '110px'}} mt={0.5} mb={1}>
                    <Divider/>
                </Box>
                <Box>
                    <Typography variant="h1" color="secondary.dark">
                        ${((price||0)/100).toFixed(2)}/mo
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body2" color="grey">
                        ${plan && price ? ((price/100)/plan).toFixed(4) : '-'}/ByteChip
                    </Typography>
                </Box>
            </Box>
        </CardContent>
    </Paper>
}

export default SubscriptionPlanCard
