import React from 'react';
import Icon, {SvgIconProps} from "./Icon";


const PlusIcon: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
  return <Icon translateY="-2px" viewBox="0 0 18 18" path="M3.75 4.25H14.25C15.0703 4.25 15.75 4.92969 15.75 5.75V6.5H2.25V5.75C2.25 4.92969 2.90625 4.25 3.75 4.25ZM15.75 8.75V13.25C15.75 14.0938 15.0703 14.75 14.25 14.75H3.75C2.90625 14.75 2.25 14.0938 2.25 13.25V8.75H15.75ZM4.875 11.75C4.66406 11.75 4.5 11.9375 4.5 12.125C4.5 12.3359 4.66406 12.5 4.875 12.5H6.375C6.5625 12.5 6.75 12.3359 6.75 12.125C6.75 11.9375 6.5625 11.75 6.375 11.75H4.875ZM7.5 12.125C7.5 12.3359 7.66406 12.5 7.875 12.5H10.875C11.0625 12.5 11.25 12.3359 11.25 12.125C11.25 11.9375 11.0625 11.75 10.875 11.75H7.875C7.66406 11.75 7.5 11.9375 7.5 12.125Z"
               {...props}/>
});

export default PlusIcon;
