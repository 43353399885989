import React from 'react';
import {useTheme} from "@mui/material/styles";
import Icon, {SvgIconProps} from "./Icon";


const PlusIcon: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
  return <Icon translateY="-2px" viewBox="0 0 18 18" path="M9.375 5.375V8.75H12.75C13.1484 8.75 13.5 9.10156 13.5 9.5C13.5 9.92188 13.1484 10.25 12.75 10.25H9.375V13.625C9.375 14.0469 9.02344 14.375 8.625 14.375C8.20312 14.375 7.875 14.0469 7.875 13.625V10.25H4.5C4.07812 10.25 3.75 9.92188 3.75 9.5C3.75 9.10156 4.07812 8.75 4.5 8.75H7.875V5.375C7.875 4.97656 8.20312 4.625 8.625 4.625C9.02344 4.625 9.375 4.97656 9.375 5.375Z"
               {...props}/>
});

export default PlusIcon;
