import {Box, Button, Typography} from "@mui/material";
import React, {useState} from "react";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import Edit from "../../icons/Edit";
import Cancel from "../../icons/Cancel";
import {ArrowRight} from "@mui/icons-material";

export interface SubscriptionCancelFlowProps {
  nextBillingDate?: Date;
  plan?: number;
  price?: number;
  defaultConfirmed?: boolean;
  onChangePlan?: () => void;
  onUnsubscribe?: () => void;
}

const SubscriptionCancelFlow = ({nextBillingDate, plan, price, onChangePlan, onUnsubscribe, defaultConfirmed}: SubscriptionCancelFlowProps) => {
  const [step, setStep] = useState(0)

  let stepComponent = null

  switch (step) {
    case 0:
      stepComponent = <Box display="flex" flexDirection="row" alignItems="center" my={1} gap={2}>
        <Button onClick={onChangePlan} variant="contained" color="primary" size="small" sx={{minWidth: '120px'}}>
          Change <Edit color="contrast" size="small" style={{marginLeft: '8px'}}/>
        </Button>
        <Button variant="outlined" color="error" size="small" onClick={() => setStep(1)} sx={{minWidth: '120px'}}>
          Unsubscribe <Cancel color="error" size="small"/>
        </Button>
      </Box>
      break
    case 1:
      stepComponent = <>
        <Box display="flex" flexDirection="row" alignItems="center" my={1}>
          <Typography variant="body1" color="error">Are you sure you want to end your subscription?</Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" my={1} gap={2}>
          <Button onClick={onUnsubscribe} variant="outlined" color="error" size="small" sx={{minWidth: '120px'}}>Confirm <ArrowRight /></Button>
        </Box>
      </>
      break
    case 2:
      break
  }

  return <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%" p={0}>
    <Box display="flex" flexDirection="row" alignItems="center">
      <SubscriptionPlanCard title="Current plan" plan={plan} price={price}/>
    </Box>
    <Box display="flex" flexDirection="row" alignItems="center" my={1}>
      <Typography variant="body1">Next billing date: {nextBillingDate ? nextBillingDate.toLocaleDateString() : '-'} </Typography>
    </Box>
    {stepComponent}
  </Box>
}

export default SubscriptionCancelFlow
